body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lead {
    text-align: justify;
}

.product-display {
    background-color: #ebeaea;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-evenly;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: .5s ease-in-out;
}

.product-display:hover {
    transform: scale(1.3);
}

.inner-page-content {
    min-height: 70vh;
}

.inner-page-header {
    height: 30vh;
    background-image: url('./_assets/bg-blue.png');
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.login-page-container {
    width: 100%;
    min-height: 87vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.auth-form {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5%;
    min-width: 30%;
    min-height: 50vh;
}

@media(max-width: 480px) {
    .auth-form {
        min-width: 90%;
        border-radius: 3%;

    }

}

.user-info {
    display: flex;
    justify-content: end;
    gap: 15px;
    background-color: blue;

}

.user-info-content {
    color: #ffff;
    display: flex;
    justify-content: end;
    gap: 15px;
}

.admin-layout {
    background-color: #f0f5ff;
    display: flex;
}

.admin-side-bar {
    background-color: #808080;
    min-height: 100vh;
}

.admin-menu-Item {
    background-color: #5c5a5a;
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.admin-menu-Item:hover {
    background-color: #4b4242;

}

.sidebar {
    background-image: url('./_assets/bg-blue.png');
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.item-destak {
    background-color: #ffff00;
    color: #000000;
    padding-bottom: 5px;


}

.legend {
    height: 70px;
    padding: 10px;
    overflow-y: hidden;
}

.mensagem-homenagem {
    color: #ffffff;
    width: 80%;
    background-color: #004C8E;
    padding: 20px;
}