.hero {
    position: relative;
    z-index: -1;
    width: 100%;
    min-height: 100vh;
}

.hero video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.hero .content {
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero .content img {
    filter: drop-shadow(10px 10px 10px #0800004d);
}

.hero .logoHero {
    width: 380px;
}