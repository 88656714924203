.navbar {
    background-color: #004C8E;
}

.item-nav {
    transition: transform 0.3s ease;
    color: #ffffff;
    font-weight: bold;
}

.item-nav:hover {
    transform: scale(1.2);
    color: #b8e62e;
}

.item-nav:active {
    transform: scale(1.2);
    color: #b8e62e;
}

.btn-colapse {
    background-color: #ffffff;
}